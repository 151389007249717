import { Link } from "react-router-dom"

const ContentPhoto = props => {
  return (

    <section className="text-gray-600 body-font">
      <div data-aos="fade-up" data-aos-duration="1500" className="container mx-auto flex px-5 py-24 2xl:px-24 md:flex-row md:justify-between flex-col items-center">
      <section className="text-gray-600 body-font">
          <div data-aos="fade-up" data-aos-duration="1500" className="flex flex-col text-center md:text-left items-center md:items-start">
              <h1 className="text-4xl md:w-5/6 sm:text-6xl font-bold leading-snug text-gray-800 py-4 pb-6" dangerouslySetInnerHTML={{__html: props.data.title}} />
              <p className="w-1/1 sm:w-4/5 text-gray-600 leading-8 font-normal text-sm">{props.data.paragraph}</p>
                  <span className="pt-6 flex flex-col sm:flex-row sm:items-center">
                      <Link to="/diensten"
                          className="w-max orange-bg text-white font-semibold md:mr-8 px-6 py-4 rounded-xl transition ease-in duration-200 transform hover:scale-105">
                          Bekijk de mogelijkheden
                      </Link>
                      <Link to="/contact" className="font-semibold text-lg mt-4 sm:mt-0 flex items-center">
                              Neem contact met ons op
                              <svg className="font-semibold ml-4" stroke="currentColor" fill="currentColor" strokeWidth="0"
                                  viewBox="0 0 24 24" height="1em" width="1em">
                                  <g>
                                      <path fill="none" d="M0 0h24v24H0z"></path>
                                      <path d="M16.004 9.414l-8.607 8.607-1.414-1.414L14.589 8H7.004V6h11v11h-2V9.414z"></path>
                                  </g>
                              </svg>
                          </Link>
                  </span>
          </div>
      </section>
      <div className="relative py-12 md:py-0 px-8 md:px-0">
          <img className="object-cover object-center rounded-xl h-full home-img" alt="hero" src={props.data.photo} />
          <div 
              className="hidden sm:block orange-gradient w-1/2 h-1/2 2xl:h-2/5 rounded-2xl absolute -top-12 2xl:-top-12 -left-16"
              style={{zIndex: "-1"}}>
          </div>
          <svg viewBox="0 0 52 24" fill="currentColor"
              className="hidden sm:block absolute -bottom-12 2xl:-bottom-20 -right-16 2xl:-right-24 w-1/2 h-1/3 p-3 2xl:h-2/5 text-gray-200"
              style={{zIndex: "-1"}}>
              <defs>
                  <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".15" height=".30">
                      <circle cx="1.25" cy="1.25" r="1.25"></circle>
                  </pattern>
              </defs>
              <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24"></rect>
          </svg>
      </div>
      </div>
  </section>
  );
}

export default ContentPhoto;