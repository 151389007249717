import PhoneIcon from "../../Assets/Icons/PhoneIcon";

const Navmenu = props => {
    return (
        <div className="relative h-full" style={{ zIndex: "999" }}>
            {
                props.menuType === "desktop" ? 
                <nav className="hidden items-center justify-center space-x-2 text-sm font-medium text-gray-800 md:flex">
                {
                    props.desktop_items.map(element => {
                        return element
                    })
                }
                </nav>
                :
                <div id="nav-menu" className="shadow-3xl w-full flex-grow lg:flex lg:items-center lg:w-auto">
                    <ul className="pt-6 lg:pt-0 list-reset lg:flex justify-end flex-1 items-center">
                        {
                            props.mobile_items.map(element => {
                                return element
                            })
                        }
                        <li className="mr-3 mb-24">
                            <p className="py-2 px-4 font-light">Volg ons nu ook op social media</p>
                            <div className="flex items-center px-4 pt-1">
                                <a href="https://www.instagram.com/administratietaxi" target="_blank" className="text-yellow-400">
                                    <svg viewBox="0 0 30 30" fill="currentColor" className="h-8">
                                        <circle cx="15" cy="15" r="4"></circle>
                                        <path
                                            d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                                        </path>
                                    </svg>
                                </a>
                                    <a href="https://www.linkedin.com/company/administratie-taxi/" target="_blank"
                                        className="ml-3 text-yellow-400">
                                        <svg fill="currentColor" stroke="currentColor" strokeLinecap="round"
                                            strokeLinejoin="round" strokeWidth="0" className="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke="none"
                                                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
                                            </path>
                                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                        </svg>
                                    </a>
                                    <a className="ml-1 text-yellow-400" href="https://www.facebook.com/AdministratieTaxi"
                                        target="_blank">
                                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            className="w-8 h-8" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                    </a>
                            </div>
                        </li>
                    </ul>
                    <div className="relative bottom-0">
                        <a href="tel:+31 6 83953173" aria-label="Our phone" title="Our phone"
                            className="absolute -bottom-2 right-4 w-max orange-bg flex items-center text-white font-semibold px-6 py-4 rounded-xl transition ease-in duration-200 transform hover:scale-105 focus:outline-none">
                            <PhoneIcon />
                            &nbsp;
                            +31 6 83953173
                        </a>
                    </div>
                </div>
            }
        </div>
    )
}

export default Navmenu;