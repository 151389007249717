const RoundedButton = props => {
    return (
        <a href={props.href} className="ml-3 w-max orange-bg cursor-pointer flex items-center text-white font-semibold px-6 py-4 rounded-xl transition ease-in duration-200 transform hover:scale-105 focus:outline-none">
           <svg className="mr-2" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em">
                <path
                    d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z">
                </path>
            </svg>
           {props.text}
        </a>
    )
}

export default RoundedButton;