const SearchIcon = () => {
    return (
        <div className="light-yellow-bg rounded-full text-6xl p-3 orange-text">
            <svg className="text-4xl p-1" stroke="currentColor" fill="none" strokeWidth="2"
                viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em"
                width="1em">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
        </div>
    )
}

export default SearchIcon