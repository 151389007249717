import Phones from "../../Assets/Phone/Phones"
import Heading from "../../Components/Headings/Heading"

const AppSectionDB = props => {
    return (
        <section id="app" className="w-screen">
            <div
                className="px-6 container mx-auto md:px-8 2xl:px-24 py-12 w-full h-full 2xl:h-auto flex flex-col md:flex-row items-center justify-center md:justify-between">
                <Heading 
                    sub_title={props.data.title} 
                    title={props.data.paragraph}
                    hasActions={false}
                    hasDownloadButtons={true} />
                <Phones />
            </div>
        </section>
    )
}

export default AppSectionDB