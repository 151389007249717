import DownloadButtons from "../Buttons/DownloadButtons";

const Heading = props => {
    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="flex flex-col text-center md:text-left items-center md:items-start">
            <p className="yellow-text font-bold">{props.sub_title}</p>
            {
                props.isSmaller ?
                <h1 className="text-3xl w-5/6 md:w-5/6 sm:text-4xl font-bold leading-snug text-gray-800 py-4 pb-6" dangerouslySetInnerHTML={{__html: props.title}} />
                :
                <h1 className="text-3xl sm:text-5xl md:w-5/6 font-bold leading-snug text-gray-800 py-4 pb-6" dangerouslySetInnerHTML={{__html: props.title}} />
            }
            <p className="w-1/1 px-4 md:px-0 sm:w-4/5 text-gray-600 leading-8 font-normal text-sm">{props.content}</p>
            {
                props.hasActions ? 
                <span className="pt-6 flex flex-col sm:flex-row sm:items-center">
                    <a href={props.actionContent1Link}
                        className="w-max orange-bg text-white font-semibold md:mr-8 px-6 py-4 rounded-xl transition ease-in duration-200 transform hover:scale-105">
                        {props.actionContent1}
                    </a>
                    {
                        props.hasMultipleActions ?
                        <a href={props.actionContent2Link} className="font-semibold text-lg mt-4 sm:mt-0 flex items-center">
                            Neem contact met ons op
                            <svg className="font-semibold ml-4" stroke="currentColor" fill="currentColor" strokeWidth="0"
                                viewBox="0 0 24 24" height="1em" width="1em">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M16.004 9.414l-8.607 8.607-1.414-1.414L14.589 8H7.004V6h11v11h-2V9.414z"></path>
                                </g>
                            </svg>
                        </a>
                        :
                        <span></span>
                    }
                </span> 
                :
                <span></span>
            }
            {
                props.hasDownloadButtons ?
                <div className="flex lg:flex-row md:flex-col">
                    <DownloadButtons />
                </div>
                :
                <span></span>
            }
        </div>
    )
}

export default Heading