const ArchiveIcon = () => {
    return (
        <div className="light-yellow-bg rounded-full text-6xl p-3 orange-text">
            <svg className="text-4xl p-1 orange-text" stroke="currentColor" fill="none" strokeWidth="2"
                viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em"
                width="1em">
                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                <rect x="1" y="3" width="22" height="5"></rect>
                <line x1="10" y1="12" x2="14" y2="12"></line>
            </svg>
        </div>
    )
}

export default ArchiveIcon