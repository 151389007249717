const ContactForm = () => {
    return (
      <div data-aos="fade-up" data-aos-duration="1500" id="contact" className="py-12">
        <div className="text-center">
            <p className="font-bold text-center py-4">Waarmee kunnen wij jou helpen?</p>
        </div>
        <form
          action="https://formsubmit.co/info@administratietaxi.nl"
          method="POST"
          className="flex flex-col w-full mx-auto transition duration-500 ease-in-out transform bg-white md:w-2/5 md:mt-0 px-8 md:px-0"
        >
          <input type="text" name="_honey" style={{ display: "none" }} />
          <div className="relative mt-4" style={{ display: "none" }}>
            <label className="text-base leading-7 text-gray-500" for="date">
              <strong>Waarmee kunnen wij jou helpen</strong>
            </label>
            <div className="relative">
              <select
                name="soort_hulp"
                className="w-full px-4 py-2 mt-2 text-base text-gray-500 transition duration-500 ease-in-out transform border border-transparent rounded-lg appearance-none bg-gray-100 focus:border-blue-500 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
              >
                <option
                  className="block mb-4 text-xs font-bold tracking-wide text-gray-500 uppercase "
                  value="1912"
                >
                  Opstarten met een nieuw bedrijf
                </option>
                <option
                  className="block mb-4 text-xs font-bold tracking-wide text-gray-500 uppercase "
                  value="1912"
                >
                  IB-aangifte, OB-aangifte en/of VPB-aangifte
                </option>
                <option
                  className="block mb-4 text-xs font-bold tracking-wide text-gray-500 uppercase "
                  value="1912"
                >
                  Financiele administratie verwerken
                </option>
                <option
                  className="block mb-4 text-xs font-bold tracking-wide text-gray-500 uppercase "
                  value="1912"
                >
                  Opstellen van een jaarrekening verwerken
                </option>
                <option
                  className="block mb-8 text-xs font-bold tracking-wide text-gray-500 uppercase "
                  value="1912"
                >
                  Salarisadministratie
                </option>
                <option
                  className="block mb-4 text-xs font-bold tracking-wide text-gray-500 uppercase "
                  value="1912"
                >
                  Debiteuren- en/of crediteurenbeheer
                </option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-gray-500">
                <svg
                  fill="#ffffff"
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                </svg>
              </div>
            </div>
          </div>


          <div className="relative mt-4">
            <label for="password" className="text-base leading-7 text-gray-500">
              Voor- en achternaam
            </label>
            <input
              type="text"
              placeholder="Anton Hendriks"
              name="naam"
              className="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-100 focus:border-gray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
            />
          </div>
          <span className="flex items-center">
            <div className="relative mt-4 w-1/2">
              <label for="password" className="text-base leading-7 text-gray-500">
                Email
              </label>
              <input
                type="email"
                placeholder="anton@mail.com"
                name="email"
                className="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-100 focus:border-gray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
              />
            </div>
            <div className="relative mt-4 ml-4 w-1/2">
              <label for="password" className="text-base leading-7 text-gray-500">
                Telefoon nummer
              </label>
              <input
                type="text"
                placeholder="06-12345678"
                name="tel-nr"
                className="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-100 focus:border-gray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
              />
            </div>
          </span>
          <div className="relative mt-4">
            <label className="text-base leading-7 text-gray-500" for="description">
              {" "}
              Je vraag{" "}
            </label>
            <textarea
              name="bericht"
              className="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-100 focus:border-gray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 apearance-none autoexpand"
              id="description"
              type="text"
              placeholder="Hoe kan ik..."
              required=""
            ></textarea>
          </div>
          <button className="w-full px-4 py-2 my-2 text-base font-medium text-white transition duration-500 ease-in-out transform orange-bg rounded-md focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-yellow-400">
            Verstuur
          </button>
        </form>
      </div>
    );
  };
  
export default ContactForm;  