// Import Swiper React components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


import ReviewCard from "../../Components/Cards/ReviewCard"

const ReviewCardsDB = props => {
    /*let reviewCardsData = [
        {
            content: "Goede service. Helpen mij altijd naar volle tevredenheid. Echt een aanrader!!!",
            client: "Taxi Town"
        },
        {
            content: "Sinds twee maanden maak ik gebruik van de service van administratie Taxi. Korte linen en advies over hoe ik miin boekhouding nog strakker voor elkaar kan hebben. Vragen en opmerkingen regelen we simpel met elkaar via WhatsApp. Handig dat ik ze ook heb kunnen koppelen aan mijn huidige boekhoudprogramma van E-boekhouden. Top zaak!",
            client: "M.B.L Chauffeur Service Holland"
        },
        {
            content: "Top boekhouder, krijg altijd het juiste advies wanneer ik vragen heb. Aanrader!",
            client: "VB express"
        },
    ]*/
    return (
        <section id="reviews" className="w-screen h-full grey-bg">
            <div className="px-6 sm:px-0 py-12 container mx-auto px-6 2xl:px-24 w-full h-full">
                <div data-aos="fade-up" data-aos-duration="1500" className="text-center">
                    <p className="yellow-text font-bold text-center">REVIEWS</p>
                    <h1 className="text-3xl sm:text-4xl font-extrabold leading-snug text-gray-800 py-6 pb-8 text-center">
                        {props.data.title}
                    </h1>
                    <p className="text-center text-gray-600 leading-8 md:w-1/2 mx-auto">
                        {props.data.content}
                    </p>
                </div>
                {
                    /*old static:
                    <div className="flex flex-col md:flex-row py-12 md:py-24 reviews-span">
                        {
                            props.data.info_cards_list_for_rewards.map((card_data, key)=>{
                                return <ReviewCard key={`reward-card-data-${key}`} content={card_data.content} client={card_data.client} />;
                            })
                        }
                    </div>
                    */
                }
                <div className="py-12 md:py-24">
                    <Swiper
                        breakpoints={{
                            280:{slidesPerView:1},
                            767:{slidesPerView:2},
                            1200:{slidesPerView:3}
                        }}
                        modules={[Navigation, Pagination]}
                        pagination={{ clickable: true }}
                        className='swiper_reviews'
                        spaceBetween={0}
                        slidesPerView={3}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >

                        {
                            props.data.info_cards_list_for_rewards.map((card_data, key)=>{
                                return <SwiperSlide key={`reward-card-data-${key}`}><ReviewCard content={card_data.content} client={card_data.client} client_photo={card_data.client_photo} /></SwiperSlide>;
                            })
                        }

                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default ReviewCardsDB