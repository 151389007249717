import Navmenu from "./Navmenu"
import RoundedButton from "../Buttons/RoundedButton";
import Logo from "../../Assets/Logo/Administratie_Taxi-Logo.png"
import MenuIcon from "../../Assets/Icons/MenuIcon";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"

const NavbarDB = props => {

    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()



    useEffect(() => {
        setIsOpen(false)
    }, [location])

    const desktop_items_menu = (props.pageSettings.nav_header!==undefined?props.pageSettings.nav_header:[])
    const desktop_items = desktop_items_menu.map((item_d, key)=>{
        if(item_d.url.indexOf('e-boekhouden.nl')!==-1){
            return (<a key={`link-${key}`} href={item_d.url} className="rounded bg-white px-3 py-2 transition hover:bg-gray-100">{item_d.title}</a>);
        }
        else{
            const url_parts = item_d.url.split('/');
            const url_path = (url_parts[url_parts.length-2]==='home'?'':url_parts[url_parts.length-2]);
            return (<Link key={`link-${key}`} to={`/${url_path}`} className="rounded bg-white px-3 py-2 transition hover:bg-gray-100">{item_d.title}</Link>);
        }
    });
    const mobile_items = desktop_items_menu.map((item_d, key)=>{
        if(item_d.url.indexOf('e-boekhouden.nl')!==-1){
            //return (<a href={item_d.url} className="rounded bg-white px-3 py-2 transition hover:bg-gray-100">{item_d.title}</a>);
            return (
                <li key={`link-${key}`} className="mr-3">
                    <a href={item_d.url} className="inline-block text-gray-800 font-semibold no-underline hover:text-gray-900 hover:text-underline py-2 px-4">{item_d.title}</a>
                </li>
            );
        }
        else{
            const url_parts = item_d.url.split('/');
            const url_path = (url_parts[url_parts.length-2]==='home'?'':url_parts[url_parts.length-2]);
            //return (<Link to={`/${url_path}`} className="rounded bg-white px-3 py-2 transition hover:bg-gray-100">{item_d.title}</Link>);
            return (<li key={`link-${key}`} className="mr-3">
                <Link to={`/${url_path}`} className="inline-block text-gray-800 font-semibold no-underline hover:text-gray-900 hover:text-underline py-2 px-4">
                    {item_d.title}
                </Link>
            </li>);
        }
    });

    const phone_number = (props.pageSettings.settings!==undefined?props.pageSettings.settings.phone:'');

    return (
        <header className="fixed top-0 w-full bg-white py-6 border-gray-100 border-b-2" style={{ zIndex: "999" }}>
            <div className="container mx-auto flex 2xl:px-24 items-center justify-between p-4 md:py-4 py-0">
                <Link to="/" className="main_link">
                    <img className="w-56" src={(props.pageSettings.settings!==undefined?props.pageSettings.settings.logo:'')} alt="Administratie Taxi" />
                </Link>
                <Navmenu desktop_items={desktop_items} menuType="desktop" />
                <div className="hidden md:block">
                    <RoundedButton href={`tel:${phone_number}`} text={phone_number} />
                </div>
                <div className="md:hidden">
                    <MenuIcon onClick={() => setIsOpen(!isOpen)} />
                </div>
            </div>
            <div className="md:hidden">
                {
                    isOpen ?
                    <Navmenu mobile_items={mobile_items} menuType="mobile" />
                    :
                    <span></span>
                }
            </div>
        </header>
    )
}

export default NavbarDB;