import Heading from "../../Components/Headings/Heading"

const HeroDB = props => {
    return (
        <section id={props.id} className="text-gray-600 body-font">
            <div className="container mx-auto flex px-4 2xl:px-24 py-12 md:py-32 md:flex-row md:justify-between flex-col items-center">
                <div className="w-full flex flex-col md:flex-row md:justify-between">
                    <Heading actionContent2Link="#contact" isSmaller={props.isSmaller}
                        sub_title={props.sub_title} title={props.data.title} content={props.data.paragraph} 
                        hasActions={props.hasActions} hasDownloadButtons={props.hasDownloadButtons}
                        actionContent1={props.actionContent1} hasMultipleActions={props.hasMultipleActions} />
                    <div data-aos="fade-up" data-aos-duration="1500" className="relative py-12 md:py-0 px-8 md:px-0">
                        <img className="object-cover object-center rounded-xl h-full about-img" alt="hero" src={props.data.photo} />
                        {/* <div className="h-full w-full flex items-center">
                            <img className="object-cover object-center rounded-xl w-1/1 md:w-3/5 my-6 md:my-0" alt="hero" src={props.img} />
                        </div> */}
                        <div 
                            className="hidden sm:block orange-gradient w-1/2 h-1/2 2xl:h-2/5 rounded-2xl absolute -top-12 2xl:-top-12 -left-16"
                            style={{zIndex: "-1"}}>
                        </div>
                        <svg viewBox="0 0 52 24" fill="currentColor"
                            className="hidden sm:block absolute -bottom-12 2xl:-bottom-20 -right-16 2xl:-right-24 w-1/2 h-1/3 p-3 2xl:h-2/5 text-gray-200"
                            style={{zIndex: "-1"}}>
                            <defs>
                                <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".15" height=".30">
                                    <circle cx="1.25" cy="1.25" r="1.25"></circle>
                                </pattern>
                            </defs>
                            <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24"></rect>
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroDB