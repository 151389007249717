import Screen from "./app-screen.png"
import Screen2 from "./app-screen-2.png"

const Phones = props => {
    return (
        <div className="w-screen sm:w-max py-12 md:py-0">
            <div data-aos="fade-up" data-aos-duration="1500" className="h-full w-full flex items-center justify-center">
                <div className="orange-gradient rounded-full absolute _30rem"></div>
                    <div className="flex justify-center relative _30rem">
                        <img src={Screen2} alt="app screen" 
                            className="h-4/6 transform absolute bottom-12 left-8 transition ease-in-out duration-600 transform hover:scale-105" />
                        <img src={Screen2} alt="app screen"
                            className="h-4/6 transform absolute bottom-12 right-8 transition ease-in-out duration-600 transform hover:scale-105" />
                        <img src={Screen} alt="app screen"
                            className="relative z-90 h-full sm:h-full transition ease-in-out duration-600 transform hover:scale-105" />
                    </div>
                </div>
            </div>
    )
}

export default Phones