const ReviewCard = props => {
    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="w-full h-max md:w-1/3 flex flex-col justify-between bg-white shadow-md md:shadow-xl px-10 py-6 md:mx-3 my-8 md:my-0 rounded-xl">
            <svg className="text-3xl orange-text" stroke="currentColor" fill="currentColor" strokeWidth="0"
                version="1.1" viewBox="0 0 16 16" height="1em" width="1em">
                <path
                    d="M3.516 7c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5l-0.016-0.5c0-3.866 3.134-7 7-7v2c-1.336 0-2.591 0.52-3.536 1.464-0.182 0.182-0.348 0.375-0.497 0.578 0.179-0.028 0.362-0.043 0.548-0.043zM12.516 7c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5l-0.016-0.5c0-3.866 3.134-7 7-7v2c-1.336 0-2.591 0.52-3.536 1.464-0.182 0.182-0.348 0.375-0.497 0.578 0.179-0.028 0.362-0.043 0.549-0.043z">
                </path>
            </svg>
            <p className="text-gray-500 leading-relaxed py-6">{props.content}</p>
            <span className="flex items-center review_client_photo">
                {
                    props.client_photo!==undefined && props.client_photo!==''?
                    <div className="light-yellow-bg w-12 h-12 rounded-full review_circle_photo" style={{backgroundImage:`url("${props.client_photo}")`}}></div>
                    :
                    <div className="light-yellow-bg w-12 h-12 rounded-full"></div>
                }
                <div className="pl-4">
                    <p>{props.client}</p>
                </div>
            </span>
        </div>
    )
}

export default ReviewCard