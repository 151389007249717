import AppleIcon from "../../Assets/Icons/AppleIcon"
import GooglePlayIcon from "../../Assets/Icons/GooglePlay"

const DownloadButtons = props => {
    return (
        <span>
            <a href="https://apps.apple.com/nl/app/e-boekhouden-nl/id1078225120" target="_blank"
                className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center mr-4 md:mt-4 mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none">
                <AppleIcon />
                <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1 uppercase">Download on the</span>
                    <span className="title-font font-medium">App Store</span>
                </span>
            </a>
            <a href="https://apps.apple.com/nl/app/e-boekhouden-nl/id1078225120" target="_blank"
                className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center mr-4 mt-4 md:mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none">
                <GooglePlayIcon />
                <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1 uppercase">Get it on</span>
                    <span className="title-font font-medium">Google Play</span>
                </span>
            </a>
        </span>
    )
}

export default DownloadButtons