import Heading from "../../Components/Headings/Heading"

const DienstenHero = props => {
    return (
        <section id={props.id} className="text-gray-600 body-font 2xl:pt-24">
            <div data-aos="fade-up" data-aos-duration="1500" className="container mx-auto flex 2xl:px-24 md:flex-row md:justify-between flex-col items-center">
                <Heading actionContent1Link="#pakketten" actionContent2Link="#contact" isSmaller={props.isSmaller}
                    sub_title={props.sub_title} title={props.title} content={props.content} 
                    hasActions={props.hasActions} hasDownloadButtons={props.hasDownloadButtons}
                    actionContent1={props.actionContent1} hasMultipleActions={props.hasMultipleActions} />
                <div className="sm:w-2/5 h-max sm:h-screen 2xl:h-auto mt-12 sm:mt-0 px-10 md:px-0 relative">
                    <div className="h-full w-full flex items-center">
                        <img className="object-cover object-center rounded-xl w-1/1 my-12 md:my-0" alt="hero" src={props.img} />
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="2000"
                        className="hidden sm:block orange-gradient w-1/2 h-1/4 2xl:h-2/5 rounded-2xl absolute top-32 2xl:-top-12 -left-16 transform -skew-x-3"
                        style={{zIndex: "-1"}}>
                    </div>
                    <svg data-aos="zoom-in" data-aos-duration="2000" viewBox="0 0 52 24" fill="currentColor"
                        className="hidden sm:block absolute bottom-24 2xl:-bottom-20 -right-16 2xl:-right-24 w-1/2 h-1/4 2xl:h-2/5 text-gray-200 transform -skew-x-3"
                        style={{zIndex: "-1"}}>
                        <defs>
                            <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".15" height=".30">
                                <circle cx="1.25" cy="1.25" r="1.25"></circle>
                            </pattern>
                        </defs>
                        <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24"></rect>
                    </svg>
                </div>
            </div>
        </section>
    )
}

export default DienstenHero