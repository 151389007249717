import Logo from "../../Assets/Logo/Administratie_Taxi-Logo.png"
import {Link} from "react-router-dom"

const FooterDB = props => {

    const desktop_items_menu = (props.pageSettings.nav_header!==undefined?props.pageSettings.nav_header:[])
    const header_menu_list = desktop_items_menu.map((item_d, key)=>{
        if(item_d.url.indexOf('e-boekhouden.nl')!==-1){
            return (<li key={`link-${key}`} className="pb-2"><a href={item_d.url}>{item_d.title}</a></li>);
        }
        else{
            const url_parts = item_d.url.split('/');
            const url_path = (url_parts[url_parts.length-2]==='home'?'':url_parts[url_parts.length-2]);
            return (<li key={`link-${key}`} className="pb-2"><Link to={`/${url_path}`}>{item_d.title}</Link></li>);
        }
    });

    const phone_number = (props.pageSettings.settings!==undefined?props.pageSettings.settings.phone:'');
    const email = (props.pageSettings.settings!==undefined?props.pageSettings.settings.email:'');

    const instagram_link = (props.pageSettings.settings!==undefined?props.pageSettings.settings.social_instagram:'');
    const linkedin_link = (props.pageSettings.settings!==undefined?props.pageSettings.settings.social_linked_in:'');
    const facebook_link = (props.pageSettings.settings!==undefined?props.pageSettings.settings.social_facebook:'');

    return (
        <div className="w-screen">
            <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-5">
                    <div className="sm:col-span-2">
                        <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
                            <img src={(props.pageSettings.settings!==undefined?props.pageSettings.settings.logo:'')} alt="Administratie Taxi" className="w-64" />
                        </a>
                        <div className="mt-3 lg:max-w-sm">
                            <p className="text-sm text-gray-800 leading-7">
                                {(props.pageSettings.settings!==undefined?props.pageSettings.settings.footer_paragraph:'')}
                            </p>
                        </div>
                    </div>
                    <div className="space-y-2 text-sm">
                        <p className="text-base font-bold tracking-wide text-gray-900">Direct naar</p>
                        <ul className="flex flex-col">
                            {/*<li className="pb-2"><Link to="/">Home</Link></li>
                            <li className="pb-2"><Link to="/diensten">Diensten</Link></li>
                            <li className="pb-2"><Link to="/contact">Contact</Link></li>
                            <li className="pb-2">
                                <a href="https://secure.e-boekhouden.nl/bh/default.asp?PRTNR=88E784EC-E4E6-4BC9-A0D9-464AFBB975DF">
                                    Inloggen
                                </a>
                            </li>*/}
                            {header_menu_list}
                        </ul>
                    </div>
                    <div className="space-y-2 text-sm">
                        <p className="text-base font-bold tracking-wide text-gray-900">Contact</p>
                        <div className="flex">
                            <p className="mr-1 text-gray-800">t:</p>
                            <a href={`tel:${phone_number}`} aria-label="Our phone" title="Our phone"
                                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">{phone_number}</a>
                        </div>
                        <div className="flex">
                            <p className="mr-1 text-gray-800">e:</p>
                            <a href={`mailto:${email}`} aria-label="Our email" title="Our email"
                                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">{email}</a>
                        </div>
                    </div>
                    <div>
                        <span className="text-base font-bold tracking-wide text-gray-900">Socials</span>
                        <div className="flex flex-row items-center mt-1 space-x-3">
                            <a href={instagram_link} target="_blank" className="text-yellow-400">
                                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                                    <circle cx="15" cy="15" r="4"></circle>
                                    <path
                                        d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                                    </path>
                                </svg>
                            </a>
                                <a href={linkedin_link} target="_blank"
                                    className="ml-3 text-yellow-400">
                                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round"
                                        strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                        <path stroke="none"
                                            d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
                                        </path>
                                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                    </svg>
                                </a>
                                <a className="ml-3 text-yellow-400" href={facebook_link}
                                    target="_blank">
                                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                        className="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                    </svg>
                                </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                    <p className="text-sm text-gray-600">
                        {(props.pageSettings.settings!==undefined?props.pageSettings.settings.copyright_paragraph_at_bottom:'')}
                    </p>
                    <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                        {/*
                        <li>
                            <Link to="/privacy-policy"
                                className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy
                                Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="/algemene-voorwaarden"
                                className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">
                                    Algemene voorwaarden
                            </Link>
                        </li>*/}
                        {
                            (props.pageSettings.nav_copyright!==undefined?props.pageSettings.nav_copyright:[]).map((data_item, key)=>{
                                
                                const url_parts = data_item.url.split('/');
                                const url_path = (url_parts[url_parts.length-2]==='home'?'':url_parts[url_parts.length-2]);
                                
                                return <li key={`copyright-menu-item-${key}`}>
                                    <Link to={`/${url_path}`}
                                        className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">
                                            {data_item.title}
                                    </Link>
                                </li>;
                            })
                        }
                    </ul>
                </div>
            </div>

            
            <div className="content_preloader"></div>

        </div>
    )
}

export default FooterDB