const HighLightedPriceCard = props => {
    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="w-full md:w-1/3 px-4 sm:px-6 lg:px-8">
            <div className="pricing-box mx-auto rounded-2xl shadow-lg overflow-hidden lg:flex">
                <div className="bg-white px-6 lg:flex-shrink-1 py-8 lg:py-6">
                    <h3 className="text-2xl leading-8 font-bold text-gray-900 sm:text-2xl sm:leading-9">
                        {props.title}
                    </h3>
                    <div className="mt-8">
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase yellow-text">
                                Wat u ontvangt
                            </h4>
                            <div className="flex-1 w-screen border-t-2 border-gray-200"></div>
                        </div>
                        <ul className="mt-8 lg:grid lg:grid-cols-1 lg:col-gap-8 lg:row-gap-5">
                            {
                                props.items_data.map((item, key) => {
                                    return (
                                        <li key={`list-item-${key}`} className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <svg className="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <p className="ml-3 text-sm leading-5 text-gray-700">
                                                {item}
                                            </p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="mt-4">
                        <p className="text-gray-900 text-3xl font-bold">
                            €{props.price}
                            <span className="text-gray-300 text-sm">
                                / maand
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HighLightedPriceCard