import PriceCard from "../Cards/PriceCard";
import HighLightedPriceCard from "../Cards/HighLightedPriceCard"

const X3CardsList = props => {


  const card_1_data = (props.data.info_cards_list_for_x3_cards_1!==undefined?props.data.info_cards_list_for_x3_cards_1.content.split("\n"):[]);
  const card_2_data = (props.data.info_cards_list_for_x3_cards_2!==undefined?props.data.info_cards_list_for_x3_cards_2.content.split("\n"):[]);
  const card_3_data = (props.data.info_cards_list_for_x3_cards_3!==undefined?props.data.info_cards_list_for_x3_cards_3.content.split("\n"):[]);

  return (

    <div id="pakketten" className="container px-5 mx-auto my-24 pt-12 sm:my-0">
        <div className="text-center py-6">
            <p className="yellow-text font-bold text-center uppercase">Pakketten</p>
        </div>
        <div className="w-full flex justify-center pt-12">
            <HighLightedPriceCard 
              title={(props.data.info_cards_list_for_x3_cards_1!==undefined?props.data.info_cards_list_for_x3_cards_1.title:'')} 
              items_data={card_1_data} 
              price={(props.data.info_cards_list_for_x3_cards_1!==undefined?props.data.info_cards_list_for_x3_cards_1.price:'')} />
        </div>
        <div className="w-full flex flex-col md:flex-row justify-center py-12">
            <PriceCard title={(props.data.info_cards_list_for_x3_cards_2!==undefined?props.data.info_cards_list_for_x3_cards_2.title:'')}  items_data={card_2_data} 
                price={(props.data.info_cards_list_for_x3_cards_2!==undefined?props.data.info_cards_list_for_x3_cards_2.price:'')}  hasAdditionalText={true} 
                priceType={(props.data.info_cards_list_for_x3_cards_2!==undefined?props.data.info_cards_list_for_x3_cards_2.price_type:'')}  />
            <PriceCard title={(props.data.info_cards_list_for_x3_cards_3!==undefined?props.data.info_cards_list_for_x3_cards_3.title:'')} items_data={card_3_data} 
                price={(props.data.info_cards_list_for_x3_cards_3!==undefined?props.data.info_cards_list_for_x3_cards_3.price:'')} 
                priceType={(props.data.info_cards_list_for_x3_cards_3!==undefined?props.data.info_cards_list_for_x3_cards_3.price_type:'')} />
        </div>
    </div>
  );
}

export default X3CardsList