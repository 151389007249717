//import Routes from "./Routes/Routes"
import RoutesDB from "./Routes/RoutesDB"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

//import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  
  const [pageSettings, sePageSettings] = useState([]);


  useEffect(() => {

    //fetch("https://administratietaxi.nl/wpadministration/wp-admin/admin-post.php?action=get_website_settings")
    fetch("https://administratietaxi.nl/wpadministration/website-settings/")
          .then((response) => response.json())
          .then((data) => {
            sePageSettings(data);

            setTimeout(() => {
              //console.log('Hello, World!')
              document.body.classList.add('settings_loaded');
            }, 1);
            setTimeout(() => {
              //console.log('Hello, World!')
              document.body.classList.add('hide_website_preloaded');
            }, 300);

          });

    AOS.init();
    AOS.refresh();
  }, []);

  //console.log('pageSettings:', pageSettings);

  return <RoutesDB pageSettings={pageSettings} />
}

export default App;