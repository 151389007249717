import WhatsappIcon from "../../Assets/Icons/WhatsappIcon"

const WhatsappButtonDB = props => {
    const phone_number = (props.pageSettings.settings!==undefined?props.pageSettings.settings.phone:'').replace(/ /g,'');
    return (
        <a style={{zIndex: "998"}} href={`https://wa.me/${phone_number}`} target="_blank" className="fixed right-4 bottom-4 bg-green-500 p-4 rounded-full flex items-center justify-center">
            <WhatsappIcon />
        </a>
    )
}

export default WhatsappButtonDB