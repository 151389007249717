// Router
import { Routes, BrowserRouter, Navigate } from "react-router-dom";
import { Route, useLocation } from "react-router";

// Lazy loading
import { lazy, Suspense, useLayoutEffect } from "react";

//import Navbar from "../Components/Navbar/Navbar";
import NavbarDB from "../Components/Navbar/NavbarDB";
//import Footer from "../Components/Footer/Footer";
import FooterDB from "../Components/Footer/FooterDB";
import HomePage from "../Pages/HomePage";
/*import ContactPage from "../Pages/ContactPage";
import DienstenPage from "../Pages/DienstenPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../Pages/TermsConditionsPage";
import { createBrowserHistory } from "history";*/
import ScrollToTop from "./ScrollToTop";

//import { home_nav_data, diensten_nav_data, contact_nav_data } from "../Data/NavData";

const AcademyRoutes = props => {



    return (
        <BrowserRouter >
            <Routes>
                <Route exact path="/index.html" element={<Navigate to="/" />} />

                {(props.pageSettings.all_menu_items!==undefined?props.pageSettings.all_menu_items:[]).map((menu_item, key)=>{

                    if(menu_item.url.indexOf('e-boekhouden.nl')!==-1){
                        return null;
                    }
                    /*else{
                        return (<li key={`link-${key}`} className="pb-2"><Link to={`/${url_path}`}>{item_d.title}</Link></li>);
                    }*/
                    const url_parts = menu_item.url.split('/');
                    const url_path = (url_parts[url_parts.length-2]==='home'?'':url_parts[url_parts.length-2]);

                    return <Route key={`router-${key}`}
                            exact
                            path={`/${url_path}`}
                            element={
                                <Suspense fallback={<div>Een ogenblik geduld.</div>}>
                                    <ScrollToTop>
                                        {/*<Navbar desktop_items={home_nav_data.desktop} mobile_items={home_nav_data.mobile} />*/}
                                        <NavbarDB pageSettings={props.pageSettings} />
                                        <HomePage pageSettings={props.pageSettings} path_name={url_path} />
                                        {/*<Footer />*/}
                                        <FooterDB pageSettings={props.pageSettings}  />
                                    </ScrollToTop>
                                </Suspense>
                            }
                        />;
                })}

                <Route path='*' element={
                    <Suspense fallback={<div>Een ogenblik geduld.</div>}>
                            <ScrollToTop>
                                {/*<Navbar desktop_items={home_nav_data.desktop} mobile_items={home_nav_data.mobile} />*/}
                                <NavbarDB pageSettings={props.pageSettings} />
                                {/*<HomePage pageSettings={props.pageSettings} />*/}
                                {/*<Footer />*/}
                                <FooterDB pageSettings={props.pageSettings}  />
                            </ScrollToTop>
                        </Suspense>
                }/>
            </Routes>
        </BrowserRouter>
    );
};

export default AcademyRoutes;