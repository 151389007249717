//import {Fragment} from 'react'

const SectionHTML = props => {

  //const thisIsMyCopy = Fragment(props.data.html)
  //const thisIsMyCopy = Fragment('<p>copy copy copy <strong>strong copy</strong></p>')

  return (
    <div>
      <h1 className="text-4xl md:text-6xl text-center font-bold">{props.data.title}</h1>
      <div className="container mx-auto px-6 px-6 py-12">
        <div dangerouslySetInnerHTML={{ __html: props.data.html }} />
      </div>
    </div>
  );
}

export default SectionHTML;