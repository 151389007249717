import InfoCardDB from "../../Components/Cards/InfoCardDB"

const InfoCardsDB = props => {
    /*let contactCardsData = [
        {
            icon: <SearchIcon />,
            title: "Openingstijden",
            content: "Maandag - Vrijdag<br>08:30 - 18:00 uur"
        },
        {
            icon: <SendIcon />,
            title: "Contactgegevens",
            content: "e: info@administratietaxi.nl<br>t: +31 6 83953173"
        },
        {
            icon: <ArchiveIcon />,
            title: "Bedrijfsgegevens",
            content: "Kvk: 85749702<br>BTW: NL004140417B34"
        },
    ]
    let infoCardsData = [
        {
            icon: <SearchIcon />,
            title: "Inzicht in uw administratie via de app",
            content: "Blijf op de hoogte van uw inkomsten en uitgaven."
        },
        {
            icon: <SendIcon />,
            title: "Facturen ontvangen en verzenden",
            content: "Betaal uw facturen op tijd en word op tijd betaald."
        },
        {
            icon: <ArchiveIcon />,
            title: "Online archief met al uw documenten",
            content: "Vind uw belangrijke documenten terug in de app."
        },
    ]*/
    return (
        <section id={props.id} className="w-screen grey-bg py-12">
            <div data-aos="fade-up" data-aos-duration="1500" className="px-6 sm:px-0 container mx-auto px-6 2xl:px-24 w-full h-full">
                <p className="yellow-text font-bold text-center">{props.data.sub_title}</p>
                {
                    props.data.title ? <h1 className="text-3xl sm:text-5xl font-bold leading-snug text-gray-800 py-6 pb-8 text-center">{props.data.title}</h1> : <h1 className="py-2"></h1>
                }
                <span className="w-full flex flex-col md:flex-row justify-between py-12 info_cards_holder">
                    {
                        /*props.isContact ?
                        contactCardsData.map(card => {
                            return <InfoCard icon={card.icon} title={card.title} content={card.content} />
                        })
                        :
                        infoCardsData.map(card => {
                            return <InfoCard icon={card.icon} title={card.title} content={card.content} />
                        })*/
                        props.data.info_cards_list.map((data_card, key)=>{
                            return <InfoCardDB key={`data_card_${key}`} data={data_card} />
                        })
                    }
                </span>
            </div>
        </section>
    )
}

export default InfoCardsDB