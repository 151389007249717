import SearchIcon from "../../Assets/Icons/SearchIcon"
import SendIcon from "../../Assets/Icons/SendIcon"
import ArchiveIcon from "../../Assets/Icons/ArchiveIcon"


const InfoCardDB = props => {

    console.log('props:', props);
    let icon = null;
    if(props.data.icon==='search'){
        icon = <SearchIcon />
    }
    else if(props.data.icon==='send'){
        icon = <SendIcon />
    }
    else if(props.data.icon==='archive'){
        icon = <ArchiveIcon />
    }

    return (
        <div data-aos="fade-up" data-aos-duration="1500" className="bg-white my-8 md:my-0 md:w-1/3 h-full text-center py-8 relative sm:mx-4 shadow-md rounded-xl">
            <div className="w-full absolute -top-8 flex justify-center">
                {icon}
            </div>
            <h1 className="font-bold pt-4 pb-2">{props.data.title}</h1>
            <p className="text-center px-8 text-gray-600" dangerouslySetInnerHTML={{__html: props.data.content}} />
        </div>
    )
}

export default InfoCardDB