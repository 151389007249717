//import AppSection from "../Sections/App/AppSection"
//import Hero from "../Sections/Heroes/Hero"
//import InfoCards from "../Sections/Cards/InfoCards"
//import ReviewCards from "../Sections/Cards/ReviewCards"
import { useEffect, useState } from "react"
//import LandingPageImage from "../Assets/taxi.jpg"
//import AboutImage from "../Assets/team.jpg"
//import ContactForm from "../Components/Forms/ContactForm"
//import { Link } from "react-router-dom"
//import WhatsappButton from "../Components/Buttons/WhatsappButton"
import WhatsappButtonDB from "../Components/Buttons/WhatsappButtonDB"

import ContentPhoto from "../Components/Sections/ContentPhoto"
import X3CardsList from "../Components/Sections/X3CardsList"
import InfoCardsDB from "../Sections/Cards/InfoCardsDB"
import AppSectionDB from "../Sections/App/AppSectionDB"
import HeroDB from "../Sections/Heroes/HeroDB"
import ReviewCardsDB from "../Sections/Cards/ReviewCardsDB"
import DienstenHero from "../Sections/Heroes/DienstenHero"
import ContactForm from "../Components/Forms/ContactForm"
import ContactHero from "../Sections/Heroes/ContactHero"
import SectionHTML from "../Components/Sections/SectionHTML"

import { useLocation } from "react-router"
import SectionFAQs from "../Sections/FAQs/Index"



const HomePage = props => {

    const [pageData, setPageData] = useState([]);
    const location = useLocation();

    const windowDataRef = () => {
        return `data_page_${props.path_name}`;
    }
    const setWindowData = (data) => {
        console.log("windowDataRef():", windowDataRef());
        window.localStorage.setItem(windowDataRef(), JSON.stringify(data));
        console.log('getWindowData() after set: ', getWindowData());
    }
    const getWindowData = () => {
        //return window[`data_page_${props.path_name}`];
        console.log('windowDataRef() from function ', windowDataRef());
        return JSON.parse(window.localStorage.getItem(windowDataRef()));
    }

    useEffect(() => {

        //document.title = "Boekhouder in Rotterdam | AdministratieTaxi";


        /*fetch(`http://localhost/projects/NoureddineIgamhiouen/wordpress/${(props.path_name!==undefined?(props.path_name===''?'home':props.path_name):'undefined')}/?r=${Math.random()}`)
            .then((response) => response.json())
            .then((data) => setPageData(data));*/
        console.log('getWindowData():', getWindowData());
        //if(getWindowData()===undefined || getWindowData()===null || getWindowData().fields===undefined || 1===1)
        fetch(`https://administratietaxi.nl/wpadministration/${(props.path_name!==undefined?(props.path_name===''?'home':props.path_name):'undefined')}/`)
            .then((response) => response.json())
            .then((data) => {
                setPageData(data);
                setWindowData( data );

                const browser_tittle = (data.fields.browser_tittle!=='' && data.fields.browser_tittle!==undefined?data.fields.browser_tittle:data.post.post_title); 
                document.title = `${data.yoast.title}`;

                document.querySelector('meta[name="description"]').setAttribute("content", data.yoast._yoast_wpseo_metadesc);


            });

        /*else
          setPageData(getWindowData());*/
        

    }, [location])
    


    console.log('pageData:', pageData);
    if(pageData===[]){
        console.log('It is empty');
        return (<div></div>);
    }

    /*if(pageData!==undefined && pageData.fields!==undefined){
        
    }*/
    //console.log('pageData before:', pageData);
    const mixed_data_content = (pageData===undefined || pageData.fields===undefined?[]:pageData.fields.custom_mixed_content);
    const content_page = mixed_data_content.map((content_details, key)=>{
        if(content_details.type==='content_photo'){
            if(content_details.sub_type==='hero')
                return (<HeroDB key={`mixed-content-${key}`} data={content_details} />);
            if(content_details.sub_type==='contact_hero')
                return <ContactHero key={`mixed-content-${key}`} isSmaller={true} img={content_details.photo} actionContent1={content_details.yellow_button_title} sub_title="" title={content_details.title} content={content_details.paragraph} hasActions={true} />;
            return (<ContentPhoto key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='Info_cards'){
            return (<InfoCardsDB key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='appications_section'){
            return (<AppSectionDB key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='review_cards'){
            return (<ReviewCardsDB key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='diensten_hero'){
            return (<DienstenHero key={`mixed-content-${key}`} img={content_details.photo} actionContent1="Bekijk de mogelijkheden" hasMultipleActions={true} 
            sub_title="" title={content_details.title} content={content_details.paragraph} hasActions={true} />);
        }
        else if(content_details.type==='x3cards'){
            return (<X3CardsList key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='contact_form'){
            return (<ContactForm key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='html'){
            return (<SectionHTML key={`mixed-content-${key}`} data={content_details} />);
        }
        else if(content_details.type==='faqs'){
            return (<SectionFAQs key={`mixed-content-${key}`} data={pageData.faqs} />);
        }
        return (<div>Undefined Content</div>);
    });
    //console.log('mixed_data_content:', mixed_data_content, pageData);
    //const iHaveContent = (pageData===[]?false:true);

    /*const data = {
      title: "Uw administratie op het juiste adres!",
      content: "Meer overzicht in uw administratieve zaken? Wij helpen u graag verder! Wij ontlasten u van uw financiële- en belastingzaken, zodat u zich zorgeloos kunt focussen op alle andere aspecten van uw onderneming."
    }*/
    const over_ons_data = {
        title: "Over ons",
        content: "Administratie Taxi is een administratiekantoor gevestigd in Rotterdam. Wij zijn gespecialiseerd in o.a. boekhouding en belastingen. Met onze financiële kennis en geavanceerde software kunnen wij uw administratie zo nauwkeurig mogelijk beheren. Uw administratie is hiermee in goede handen, zodat u zich volledig kunt focussen op ondernemen. Wij nodigen u graag uit voor een oriënterend gesprek. Hierin behandelen wij uw wens en vertellen wij wat u van Administratie Taxi kunt verwachten. Naar aanleiding van dit gesprek stellen wij een offerte op voor een dienstenpakket. Deze wordt op basis van uw voorkeuren samengesteld."
      }

    return (
        <div className="pt-24 md:pt-32 2xl:pt-40">
            {/*<WhatsappButton />*/}
            <WhatsappButtonDB pageSettings={props.pageSettings} />



                {content_page}


                {
                    /*
                    <section className="text-gray-600 body-font">
                    <div data-aos="fade-up" data-aos-duration="1500" className="container mx-auto flex px-5 py-24 2xl:px-24 md:flex-row md:justify-between flex-col items-center">
                    <section className="text-gray-600 body-font">
                        <div data-aos="fade-up" data-aos-duration="1500" className="flex flex-col text-center md:text-left items-center md:items-start">
                            <h1 className="text-4xl md:w-5/6 sm:text-6xl font-bold leading-snug text-gray-800 py-4 pb-6" dangerouslySetInnerHTML={{__html: data.title}} />
                            <p className="w-1/1 sm:w-4/5 text-gray-600 leading-8 font-normal text-sm">{data.content}</p>
                                <span className="pt-6 flex flex-col sm:flex-row sm:items-center">
                                    <Link to="/diensten"
                                        className="w-max orange-bg text-white font-semibold md:mr-8 px-6 py-4 rounded-xl transition ease-in duration-200 transform hover:scale-105">
                                        Bekijk de mogelijkheden
                                    </Link>
                                    <Link to="/contact" className="font-semibold text-lg mt-4 sm:mt-0 flex items-center">
                                            Neem contact met ons op
                                            <svg className="font-semibold ml-4" stroke="currentColor" fill="currentColor" strokeWidth="0"
                                                viewBox="0 0 24 24" height="1em" width="1em">
                                                <g>
                                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                                    <path d="M16.004 9.414l-8.607 8.607-1.414-1.414L14.589 8H7.004V6h11v11h-2V9.414z"></path>
                                                </g>
                                            </svg>
                                        </Link>
                                </span>
                        </div>
                    </section>
                    <div className="relative py-12 md:py-0 px-8 md:px-0">
                        <img className="object-cover object-center rounded-xl h-full home-img" alt="hero" src={LandingPageImage} />
                        <div 
                            className="hidden sm:block orange-gradient w-1/2 h-1/2 2xl:h-2/5 rounded-2xl absolute -top-12 2xl:-top-12 -left-16"
                            style={{zIndex: "-1"}}>
                        </div>
                        <svg viewBox="0 0 52 24" fill="currentColor"
                            className="hidden sm:block absolute -bottom-12 2xl:-bottom-20 -right-16 2xl:-right-24 w-1/2 h-1/3 p-3 2xl:h-2/5 text-gray-200"
                            style={{zIndex: "-1"}}>
                            <defs>
                                <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".15" height=".30">
                                    <circle cx="1.25" cy="1.25" r="1.25"></circle>
                                </pattern>
                            </defs>
                            <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24"></rect>
                        </svg>
                    </div>
                    </div>
                </section>
                    */
                }




            {/*
        <InfoCards title="Waarom wij met e-Boekhouden werken" />
            */}



            {/*<AppSection />*/}




            {/*<Hero id="about" img={AboutImage} sub_title={over_ons_data.sub_title} title={over_ons_data.title} content={over_ons_data.content} hasActions={false} />*/}



            {/*<ReviewCards />*/}

        </div>
    )
}

export default HomePage